import React from "react";
import { graphql } from "gatsby";
import { RichText, Date } from "prismic-reactjs";
import { withPreview } from "gatsby-source-prismic";
import { Facebook, Twitter } from "@material-ui/icons";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import Layout from "../components/layout/index";
import {
  ImageCaption,
  Quote,
  Text,
  ImageGallery,
  VideoMedia,
} from "../components/slices";
import Categories from "../components/Listing/Categories";
import SEO from "../components/seo";
import { WrapperShare } from "../components/Style/BlogCss";

const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "text":
          return (
            <div key={index}>
              <Text slice={slice} />
            </div>
          );
        case "quote":
          return (
            <div key={index}>
              <Quote slice={slice} />
            </div>
          );
        case "banner_with_caption":
          return (
            <div key={index}>
              <ImageCaption slice={slice} />
            </div>
          );
        case "image_gallery":
          return (
            <div key={index}>
              <ImageGallery slice={slice} />
            </div>
          );
        case "call_to_action":
          return (
            <div key={index}>
              <VideoMedia slice={slice} />
            </div>
          );
        default:
      }
    })();
    return res;
  });
};

const PostBody = ({ blogPost, site, url }) => {
  return (
    <>
      <div
        style={{
          padding: "1em 0em",
        }}
      >
        <h1>
          {RichText.asText(blogPost.title.raw).length !== 0 &&
            RichText.asText(blogPost.title.raw)}
        </h1>
        <div
          style={{
            color: "rgba(117, 117, 117, 1)",
            padding: "0.6em 0em",
            fontSize: "0.85rem",
          }}
        >
          <time>
            {new Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }).format(Date(blogPost.date))}
          </time>
        </div>
      </div>
      <PostSlices slices={blogPost.body} />
      <div
        style={{
          textTransform: "uppercase",
          fontSize: "0.9rem",
          padding: "0.6em 0em 0.6em",
          display: "flex",
        }}
      >
        {blogPost.categories.length !== 0 && (
          <Categories
            categories={blogPost.categories}
            tag={true}
            style={{ display: "flex", flexWrap: "wrap" }}
          />
        )}
      </div>
      <WrapperShare>
        <FacebookShareButton url={`${site}${url}`} className="button">
          <Facebook className="icon" />
        </FacebookShareButton>
        <TwitterShareButton
          url={`${site}${url}`}
          title={RichText.asText(blogPost.title.raw)}
          className="button"
        >
          <Twitter className="icon" />
        </TwitterShareButton>
      </WrapperShare>
    </>
  );
};

const PostBlog = ({
  data,
  pageContext: {
    data: { title, description, body },
    url,
  },
  location: { pathname },
}) => {
  const getImage = body.filter((o) => o.slice_type === "banner_with_caption");

  return (
    <Layout>
      <SEO
        title={title.text}
        description={description.text}
        urlImage={
          getImage.length > 0 ? getImage[0].primary.image_banner.url : ""
        }
        pathname={pathname}
      />
      <div style={{ padding: "2em 1.5em", maxWidth: 1200, margin: "0 auto" }}>
        {data.prismicPostBlog !== null && (
          <PostBody
            blogPost={data.prismicPostBlog.data}
            site={data.site.siteMetadata.siteUrl}
            url={url}
          />
        )}
      </div>
    </Layout>
  );
};

export default withPreview(PostBlog);

export const query = graphql`
  query ($language: String!, $uid: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicPostBlog(uid: { eq: $uid }, lang: { eq: $language }) {
      id
      uid
      lang
      type
      url
      data {
        title {
          raw
        }
        description {
          text
        }
        date
        categories {
          category {
            id
            document {
              ... on PrismicCategory {
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicPostBlogBodyText {
            slice_label
            slice_type
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPostBlogBodyQuote {
            slice_label
            slice_type
            primary {
              quote {
                raw
              }
              name_of_the_author {
                raw
              }
            }
          }
          ... on PrismicPostBlogBodyBannerWithCaption {
            id
            slice_label
            slice_type
            primary {
              image_banner {
                alt
                url
              }
              description {
                raw
              }
            }
          }
          ... on PrismicPostBlogBodyImageGallery {
            id
            slice_label
            slice_type
            primary {
              name_of_the_gallery {
                raw
              }
            }
            items {
              image_captions {
                raw
              }
              gallery_image {
                url
              }
            }
          }
          ... on PrismicPostBlogBodyCallToAction {
            id
            slice_label
            slice_type
            items {
              video_media {
                url
              }
            }
          }
        }
      }
    }
  }
`;
